var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('b-overlay',{attrs:{"show":_vm.overlayFlag,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('code',[_vm._v("* ")]),_vm._v("สาขา : "),_c('multiselect',{class:{
                        'is-invalid':
                          _vm.submitform && _vm.$v.branchId.$error,
                      },attrs:{"options":_vm.userBranch,"label":"nameTh"},model:{value:(_vm.branchId),callback:function ($$v) {_vm.branchId=$$v},expression:"branchId"}}),(_vm.submitform && _vm.$v.branchId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.branchId.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" รหัสคลัง : "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.code.$error,
                        },model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),(_vm.submitform && _vm.$v.code.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.code.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("ชื่อ (ไทย): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.nameTh.$error,
                        },model:{value:(_vm.nameTh),callback:function ($$v) {_vm.nameTh=$$v},expression:"nameTh"}}),(_vm.submitform && _vm.$v.nameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.nameTh.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ชื่อ (อังกฤษ): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.nameEn.$error,
                        },model:{value:(_vm.nameEn),callback:function ($$v) {_vm.nameEn=$$v},expression:"nameEn"}}),(_vm.submitform && _vm.$v.nameEn.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.nameEn.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_vm._v("ประเภท : "),_c('multiselect',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.type.$error,
                        },attrs:{"options":_vm.typeOption,"label":"nameTh"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),(_vm.submitform && _vm.$v.type.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.type.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_vm._v("ใช้กับ : "),_c('multiselect',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.dept.$error,
                        },attrs:{"options":_vm.deptOption,"label":"nameTh"},model:{value:(_vm.dept),callback:function ($$v) {_vm.dept=$$v},expression:"dept"}}),(_vm.submitform && _vm.$v.dept.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.dept.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('label',{attrs:{"for":""}}),_c('br'),_c('b-button',{staticClass:"btn float-end m-1",attrs:{"variant":"success"},on:{"click":_vm.tooltipForm}},[_vm._v(" บันทึก ")])],1)])])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }